/**=====================
     Product Page scss
==========================**/
.pt-25 {
  padding-top: 25px;
}

.review-title-2 {
  h4 {
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    color: $title-color;
    font-weight: 400;
  }

  p {
    margin-bottom: calc(7px + 6 * (100vw - 320px) / 1600);
    margin-top: 3px;
    font-size: 15px;
    line-height: 1.6;
    color: $content-color;
  }

  button {
    width: 100%;
    padding: 10px 14px;
    background-color: $light-gray !important;
    color: $content-color !important;
    border: 1px solid #eee !important;
    font-size: 16px;

    &:hover {
      background-color: $light-gray;
      color: $content-color;
      border: 1px solid $border-color;
    }
  }
}

.product-load-more {
  .col-grid-box {
    display: none;
  }
}

.product-title {
  margin-top: 20px;

  h4 {
    font-weight: 600;
    margin-bottom: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.4px;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
  }
}

.product-rating-box {
  .product-main-rating {
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    gap: 14px;

    h2 {
      font-weight: 500;
      @include flex_common;
      gap: 5px;
      font-size: calc(28px + (37 - 28) * ((100vw - 320px) / (1920 - 320)));

      i {
        font-size: calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
        font-weight: normal;
        color: $rating-color;
      }
    }

    h5 {
      line-height: 1.4;
      font-weight: 400;
      color: $content-color;
      font-size: 17px;
    }
  }

  .product-rating-list {
    display: grid;
    gap: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid $border-color;
    padding-bottom: calc(13px + (25 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(13px + (25 - 13) * ((100vw - 320px) / (1920 - 320)));

    li {
      &:nth-child(4) {
        .rating-product {
          .progress {
            .progress-bar {
              background-color: #ffa53b;
            }
          }
        }
      }

      &:last-child {
        .rating-product {
          .progress {
            .progress-bar {
              background-color: #ff4f4f;
            }
          }
        }
      }

      .rating-product {
        display: flex;
        gap: 12px;
        align-items: center;

        h5 {
          white-space: nowrap;
          display: flex;
          align-items: center;
          gap: 3px;
          font-size: 18px;
          width: 40px;
          font-weight: 600;

          i {
            font-size: 15px;
            font-weight: normal;
          }
        }

        .progress {
          width: calc(100% - 12px - 28px - 15px);
          height: 9px;
          border-radius: 100px;

          .progress-bar {
            background-color: var(--theme-color);
            border-radius: 100px;
          }
        }

        .total {
          white-space: nowrap;
          width: auto;
          color: rgba($content-color, 0.878);
          font-size: 16px;
          font-weight: 400;
          width: 15px;
          display: block;
        }
      }
    }
  }
}

.review-people {
  border-left: 1px solid $border-color;
  padding-left: 23px;
  max-height: 421px;
  height: 100%;
  overflow: auto;

  body.rtl & {
    border-right-width: 1px;
    border-right-style: solid;
    padding-right: 23px;
    border-left: unset;
    padding-left: unset;
  }

  &::-webkit-scrollbar-track {
    border-radius: -2px;
    // background-color: rgba($color: #555, $alpha: 0.14);
  }

  // &::-webkit-scrollbar {
  //   width: 4px;
  //   background-color: #f5f5f5;
  //   border-radius: 50px;
  // }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba($color: #555, $alpha: 0.5);
   
    
  }

  @include mq-max(lg) {
    border: none;
    padding: 0;
    margin-top: 25px;
  }

  .review-list {
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 17px);

    body.rtl & {
      padding-right: 0;
    }

    li {
      display: block;
      width: 100%;

      .people-box {
        display: flex;
        gap: 15px;
        width: 100%;
        border-radius: 10px;
        padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)))
          calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
        background: $light-gray;

        @include mq-max(xs) {
          display: block;
        }

        &:hover {
          .reply {
            a {
              opacity: 1;
            }
          }
        }

        .people-image {
          @include pseudowh($width: 70px, $height: 70px);
          margin: 0 auto 8px;

          @include mq-max(xs) {
            margin: 0 auto;
          }

          img {
            @include pseudowh;
            border-radius: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;

            @include mq-max(sm) {
              border-radius: 8px;
            }
          }
        }

        .people-text {
          .user-round {
            width: 44px;
            height: 46px;
            object-fit: contain;
            background-color: $white;
            @include flex_common;
            font-size: 40px;
            border-radius: 7px;
            box-shadow: 0 0 6px rgba(34, 34, 34, 0.16);
            margin: 0 auto;

            h4 {
              font-size: 41px;
              font-weight: 600;
              color: var(--theme-color);
            }
          }
        }

        .name-user {
          @include flex_common;
          @include pseudowh($width: 70px, $height: 70px);
          background-color: #ececec;
          border-radius: 10px;
          color: $content-color;

          h3 {
            font-size: 37px;
            font-weight: 600;
          }
        }

        .people-comment {
          width: calc(100% - 70px - 15px);

          .people-name {
            display: flex;
            align-items: center;
            gap: 8px;

            @media (max-width: 400px) {
              display: block;
            }

            h5 {
              .name {
                display: block;
                font-weight: 600;
                font-size: 17px;

                &:hover {
                  color: var(--theme-color);
                }
              }
            }

            .date-time {
              width: 100%;
              margin-top: 4px;

              h6 {
                font-size: 13px;
                color: #777;
                margin-block: 4px 6px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
              }
            }

            .product-rating {
              @include flex_common;
            }
          }

          .name {
            white-space: nowrap;
            display: block;
            font-weight: 600;
            font-size: calc(
              14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))
            );

            &:hover {
              color: var(--theme-color);
            }
          }

          body.rtl & {
            padding-left: unset;
            padding-right: 15px;
          }

          @include mq-max(xs) {
            padding: 0;
            width: 100%;
            margin-top: 16px;
          }

          .date-time {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0;

            h6 {
              font-size: 13px;
              color: #777;
              margin: 0;
            }
          }
        }

        .reply {
          margin-top: 6px;
          line-height: 1.6;
          color: $content-color;
          position: relative;

          p {
            margin: 0;
            line-height: 1.6;

            @include mq-max(xs) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.vendor-box {
  padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
  background-color: $light-gray;
  overflow: hidden;

  .vendor-contain {
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
    padding: 15px;
    background-color: $white;
    align-items: center;

    .vendor-text {
      width: 44px;
      height: 44px;
      @include flex_common;
      background-color: $light-gray;
    }

    .vendor-image {
      @include pseudowh($width: 64px, $height: 64px);
      object-fit: contain;

      img {
        @include pseudowh;
        object-fit: contain;
      }
    }
  }

  .vendor-list {
    margin-top: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-top: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-top: 1px solid $border-color;

    ul {
      @include flex_wrap(
        $dis: flex,
        $wrap: wrap,
        $gap: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)))
      );
      align-items: center;

      body.rtl & {
        padding-right: 0;
      }

      li {
        display: block;

        .address-contact {
          @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
          align-items: center;

          .feather {
            @include pseudowh($width: 20px, $height: 20px);
            color: var(--theme-color);
          }

          i {
            font-size: 20px;
            line-height: 1;
            color: var(--theme-color);
          }

          h5 {
            font-weight: 600;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 2px 10px;

            span {
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .vendor-detail {
    margin: calc(13px + (28 - 13) * ((100vw - 320px) / (1920 - 320))) 0 0;
    line-height: 1.7;
    color: $content-color;
  }
}

.hot-line-number {
  padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
  background-color: $light-gray;

  h5 {
    font-weight: 600;
    margin-bottom: 4px;
  }

  h6 {
    color: $content-color;
    margin-bottom: 17px;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
  }
}

.product-category {
  gap: 25px;
  padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product-section {
  .product-left-box {
    position: sticky;
    top: 0;

    .slider-image {
      background-color: $light-gray;

      .ngxImageZoomFullContainer {
        &.ngxImageZoomLensEnabled {
          border-radius: 5px !important;
          border: 1px solid #eee;
        }
      }
    }

    .bottom-slider-image {
      .slider-image {
        background-color: $light-gray;

        img {
          cursor: pointer;
          opacity: 0.6;
        }

        &.active {
          img {
            opacity: 1;
          }
        }
      }
    }

    .product-main-1 {
      .owl-nav {
        [class*="owl-"] {
          position: absolute;
          top: 50%;
          border-radius: 100%;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0;
          background-color: #ffffff;
          transform: scale(0.86) translateY(-50%);
          transition: all 0.5s ease-in-out;
          box-shadow: 0 0 8px rgb(221 221 221 / 60%);
          padding: 0;
          margin: 0;
          line-height: 1;
          border: 1px solid #ececec;

          &::before {
            @include remixicon;
            font-size: 22px;
            color: rgba(34, 34, 34, 0.88);
          }
        }

        .owl-next {
          right: -18px;

          @include mq-max(lg) {
            right: calc(1px + (5 - 1) * ((100vw - 320px) / (991 - 320)));
          }

          &::before {
            content: "\ea6e";
          }
        }

        .owl-prev {
          left: -18px;

          @include mq-max(lg) {
            left: calc(1px + (5 - 1) * ((100vw - 320px) / (991 - 320)));
          }

          &::before {
            content: "\ea64";
          }
        }
      }
    }
  }

  .product-label-group {
    position: absolute;
    top: 24px;
    left: 0;

    .product-label-tag {
      padding: 5px 13px;
      background-color: #ff7272;
      font-size: 14px;
      color: $white;
      font-weight: 500;
      position: relative;
      z-index: 1;
      width: max-content;

      &::before {
        @include pos;
        width: 15px;
        height: 15px;
        top: 0;
        right: -15px;
        border-bottom: solid 15px #ff7272;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        transform: rotate(180deg);
        z-index: 1;
      }

      &::after {
        @include pos;
        width: 15px;
        height: 15px;
        top: 16px;
        right: -15px;
        border-bottom: solid 15px #ff7272;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
        transform: rotate(0);
        z-index: 1;
      }

      span {
        font-weight: 700;
      }

      &.warning-label-tag {
        background-color: #ffba00;

        &::before,
        &::after {
          border-bottom-color: #ffba00;
        }
      }

      + .product-label-tag {
        margin-top: 13px;
      }
    }
  }

  .left-slider-image {
    .sidebar-image {
      border-radius: 8px;
      overflow: hidden;

      img {
        @include pseudowh($width: auto, $height: auto);
        cursor: pointer;
        object-fit: contain;
      }
    }
  }

  .right-box-contain {
    position: sticky;
    top: 10px;
    left: 0;

    @include mq-max(md) {
      text-align: center;
    }

    .offer-top {
      font-weight: 500;
      padding: 5px calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba($danger-color, $alpha: 0.1);
      border-radius: 5px;
      color: $danger-color;
      display: inline-block;
      margin-bottom: 0;
      margin-left: 12px;

      body.rtl & {
        margin-left: unset;
        margin-right: 12px;
      }
    }

    .name {
      font-weight: 700;
      margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    }

    .price-rating {
      @include flex_common($dis: flex, $align: center, $justify: space-between);
      gap: 5px;
      flex-wrap: wrap;

      &-2 {
        @include mq-max(3xl) {
          display: block;
        }

        .custom-rate {
          @include mq-max(3xl) {
            margin-top: 5px;
          }
        }
      }

      @include mq-max(md) {
        display: block;
      }

      &-box {
        @include mq-max(3xl) {
          @include flex_common(
            $dis: flex,
            $align: center,
            $justify: space-between
          );
        }

        @include mq-max(xs) {
          display: block;
        }

        .custom-rate {
          @include mq-max(3xl) {
            margin-top: 0 !important;
          }

          @include mq-max(xs) {
            margin-top: 10px !important;
          }
        }
      }

      .custom-rate {
        @include mq-max(md) {
          margin-top: 10px;
          justify-content: center;
        }
      }

      .price {
        font-weight: 600;
        font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));

        del {
          font-weight: 400;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          margin: 0 calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)));
        }

        span {
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
        }
      }

      .custom-rate {
        ul {
          li {
            .feather {
              @include pseudowh($width: 16px, $height: 16px);
            }
          }
        }

        .review {
          font-size: 13px;
          margin-left: 8px;
          color: $title-color;

          body.rtl & {
            margin-left: unset;
            margin-right: 12px;
          }
        }
      }
    }

    .product-contain {
      border-bottom: 1px solid $border-color;
      padding-bottom: 16px;

      p {
        color: $content-color;
        line-height: 1.7;
        margin: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) 0 0;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        width: 100%;

        @include mq-max(md) {
          width: 100%;
        }
      }
    }

    .product-package {
      .d-flex {
        @include mq-max(md) {
          justify-content: center;
        }
      }
      .select-package {
        @include flex_wrap(
          $dis: flex,
          $wrap: wrap,
          $gap: calc(5px + (13 - 5) * ((100vw - 320px) / (1920 - 320)))
        );

        @include mq-max(md) {
          justify-content: center;
        }

        body.rtl & {
          padding-right: 0;
        }

        li {
          border: 1px solid rgba(154, 154, 154, 0.4);
          border-radius: 6px;
          padding: 3px;
          transition: all 0.3s ease-in-out;

          a,
          button {
            padding: 6px 11px;
            border: 1px solid $border-color;
            border-radius: 4px;
            display: block;
            color: $content-color;
            font-size: 14px;
            transition: all 0.3s ease-in-out;
            background: transparent;
          }

          &:hover {
            border: 1px solid rgba(154, 154, 154, 0.65);

            button,
            img {
              border-color: rgba(154, 154, 154, 0.65);
            }
          }

          &.active {
            border: 1px solid var(--theme-color);
            border-radius: 6px;
            padding: 3px;

            button,
            img {
              background: var(--theme-color);
              color: $white;
              font-weight: 600;
            }

            img {
              background: $white;
            }
          }

          &.disabled {
            overflow: hidden;
            position: relative;
            user-select: none;
            cursor: default;
            opacity: 0.6 !important;
            pointer-events: none;

            &::before {
              @include pos;
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
              left: 0;
              background-color: #ff7272;
              width: 100%;
              height: 1px;
              cursor: default;
              user-select: none;
              z-index: 1;
            }

            a,
            button {
              cursor: default;
            }
          }
        }
      }

      .color {
        li {
          opacity: 0.7;

          &.active {
            opacity: 1;
            border-color: $title-color;
          }

          button {
            width: 36px !important;
            height: 36px !important;
          }
        }
      }

      .circle {
        li {
          border: 1px solid rgba(154, 154, 154, 0.4);
          border-radius: 100% !important;
          padding: 3px;
          transition: all 0.3s ease-in-out;

          &:hover {
            button {
              color: #222;
              background-color: #f1f0f0;
            }
          }

          &.active {
            button {
              color: #ffffff !important;
              background-color: var(--theme-color);
            }
          }

          button {
            @include flex_common;
            width: calc(
              30px + (35 - 30) * ((100vw - 320px) / (1920 - 320))
            ) !important;
            height: calc(
              30px + (35 - 30) * ((100vw - 320px) / (1920 - 320))
            ) !important;
            background-color: #f8f8f8;
            border-radius: 100%;
            color: $content-color !important;
            padding: 0;
            border: unset;
            font-weight: 400;
          }
        }
      }

      .form-check {
        margin: 0;
        padding: 0;
        min-height: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          .form-check-input {
            border-color: rgba(154, 154, 154, 0.65);
          }

          .form-check-label {
            color: $title-color;
          }
        }

        + .form-check {
          margin-left: 15px;

          body.rtl & {
            margin-left: unset;
            margin-right: 15px;
          }
        }

        .form-check-input {
          cursor: pointer;
          float: unset;
          margin: 0;
          width: 16px;
          height: 16px;
          background-color: #f8f8f8;
          border: 1px solid rgba(154, 154, 154, 0.4);
          position: relative;
          @include flex_common;

          &::after {
            @include pos($pos: relative, $content: "");
            width: 12px;
            height: 12px;
            background-color: var(--theme-color);
            border-radius: 100%;
            transition: 0.3s ease-in-out;
            transform: scale(0);
          }

          &:focus {
            box-shadow: unset;
          }

          &:active {
            filter: unset;
          }

          &:checked {
            background-color: $white;
            border-color: var(--theme-color);

            &::after {
              transform: scale(1);
            }

            ~ .form-check-label {
              color: $title-color;
            }
          }
        }

        .form-check-label {
          line-height: 1;
          font-size: 16px;
          font-weight: 600;
          margin-top: 1px;
          color: $content-color;
          cursor: pointer;
        }
      }

      .form-select {
        background: linear-gradient(187.77deg, #fafafa 5.52%, #f8f8f8 94%);
        border-radius: 7px;
        border: 1px solid #eee;
        display: inline-block;
      }

      .image {
        li {
          border: 1px solid rgba(154, 154, 154, 0.4);
          border-radius: 6px;
          padding: 3px;

          button,
          img {
            width: 65px;
            border-radius: 4px;
            cursor: pointer;
            height: 65px;
            padding: 0;
            overflow: hidden;
          }
        }
      }

      .rectangle {
        li {
          border: 1px solid rgba(154, 154, 154, 0.4);
          border-radius: 6px;
          padding: 3px;

          &:hover {
            button {
              color: #222;
              background-color: #f1f0f0;
            }
          }

          &.active {
            button {
              color: $white;
              background-color: var(--theme-color);
            }
          }

          button {
            background-color: #f8f8f8;
            border: unset;
          }
        }
      }
    }

    .note-box {
      @include flex_wrap(
        $dis: flex,
        $wrap: wrap,
        $gap: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320)))
      );
      align-items: center;
      margin-top: 20px;

      @include mq-max(md) {
        justify-content: center;
      }

      .compare-button {
        background-color: $light-gray;
      }

      .cart-button {
        background-color: var(--theme-color);
        text-transform: uppercase;

        i {
          animation: ring 4s 0.7s ease-in-out infinite;
          line-height: 1;
          margin-top: -3px;
          font-size: 19px;
        }
      }

      .product-qty {
        width: auto;
        margin-top: 0;

        @media (max-width: 1560px) {
          max-width: 125px;
        }

        @include mq-max(2xs) {
          width: 160px;
        }

        .input-group {
          background: linear-gradient(
            187.77deg,
            #fafafa 5.52%,
            $light-gray 94%
          );
        }
      }

      .wishlist-btn-group {
        display: flex;
        gap: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320)));

        .wishlist-button {
          padding: 0;
          width: calc(38px + (46 - 38) * ((100vw - 320px) / (1920 - 320)));
          height: calc(38px + (46 - 38) * ((100vw - 320px) / (1920 - 320)));
          border: 1px solid #eee;
          border-radius: 100%;
          background-color: #f8f8f8;
          transition: none;

          i {
            font-size: calc(
              18px + (20 - 18) * ((100vw - 320px) / (1920 - 320))
            );
            line-height: 1;
          }
        }
      }
    }

    .buy-now-button {
      margin-top: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid $border-color;

      button {
        background-color: #ff7272;
      }
    }

    .buy-box {
      @include flex_wrap($dis: flex, $wrap: wrap, $gap: 11px);
      align-items: center;
      margin-top: 20px;

      .team-box {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        input {
          margin-top: -7px;
          display: block;
        }

        .form-check-label {
          span {
            display: flex;
            align-items: center;

            a {
              text-decoration: underline;
              margin-left: 3px;
              color: $title-color;
            }
          }
        }
      }

      @include mq-max(md) {
        justify-content: center;
      }

      a {
        @include flex_wrap(
          $dis: flex,
          $wrap: wrap,
          $gap: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)))
        );
        font-size: 16px;
        align-items: center;
        color: #777;

        &:hover {
          color: #222;
        }

        i {
          font-size: 17px;
        }
      }
    }

    .pickup-box {
      margin-top: 20px;

      .product-info {
        margin-top: 20px;

        .product-info-list {
          @include flex_wrap(
            $dis: flex,
            $wrap: wrap,
            $gap: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)))
          );
          align-items: flex-start;
          text-align: left;
          display: inline-block;
          column-count: 2;
          background-color: #f9f9f9;
          padding: 15px;
          border-radius: 12px;
          width: 80%;
          max-width: 390px;

          @include mq-max(xs) {
            column-count: 1;
            padding: 15px;
          }

          &-2 {
            display: flex;

            @media (max-width: 1500px) {
              width: 100%;
            }

            @media (max-width: 1450px) {
              column-count: 1;
              padding: 15px;
            }

            @include mq-max(xl) {
              column-count: 2;
              display: block;
              padding-bottom: 4px;

              li {
                margin-bottom: 8px;
              }
            }

            @include mq-max(md) {
              margin-inline: auto;
            }

            @include mq-max(xs) {
              display: flex;
              padding-bottom: 15px;

              li {
                margin-bottom: 0;
              }
            }
          }

          &-3 {
            width: 50%;

            li {
              margin-bottom: 7px;

              @include mq-max(2xs) {
                margin-bottom: 0;
              }
            }

            @include mq-max(2xl) {
              width: 80%;
            }

            @include mq-max(md) {
              width: 100%;
            }

            @include mq-max(2xs) {
              display: flex;
            }
          }

          li {
            padding-left: 13px;
            width: 100%;
            font-size: calc(
              14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))
            );
            position: relative;
            color: #777;

            body.rtl & {
              padding-left: unset;
              padding-right: 13px;
              text-align: right;
            }

            @include mq-max(sm) {
              width: auto;
              margin-left: 18px;
            }

            @include mq-max(xs) {
              margin-left: unset;
              width: 100%;
              padding-left: unset;

              &::after {
                content: none !important;
              }
            }

            &::after {
              @include pos;
              @include center(vertical);
              @include pseudowh($width: 5px, $height: 5px);
              left: 0;
              background-color: $content-color;
              border-radius: 100%;

              body.rtl & {
                left: unset;
                right: 0;
              }
            }

            a {
              margin-left: 5px;
              color: $title-color;
              font-weight: 500;

              + a {
                margin-left: 0;
              }
            }
          }
        }
      }

      .pickup-icon {
        i {
          font-size: 20px;
          color: var(--theme-color);
        }
      }

      .pickup-detail {
        h4 {
          width: 90%;
          font-weight: 400;
          margin-bottom: 5px;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          line-height: 1.6;

          @include mq-max(md) {
            width: 100%;
          }
        }

        h6 {
          margin-top: 4px;
        }

        a {
          margin-top: 0;
          color: var(--theme-color);
          display: inline-block;
        }
      }
    }

    .paymnet-option {
      .payment-img {
        width: 52%;

        @include mq-max(sm) {
          width: 66%;
        }

        @include mq-max(xs) {
          width: 73%;
        }
      }

      ul {
        align-items: center;
        @include flex_wrap(
          $dis: flex,
          $wrap: wrap,
          $gap: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320)))
        );

        @include mq-max(md) {
          justify-content: center;
        }

        body.rtl & {
          padding-right: 0;
        }
      }
    }

    .share-option {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $border-color;

      ul {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 20px);

        @include mq-max(md) {
          justify-content: center;
        }

        li {
          display: block;

          &:hover {
            a {
              transform: translateY(-4px);
            }
          }

          a {
            display: block;
            text-align: center;
            font-size: 18px;
            color: $content-color;
            transition: all 0.5s ease;

            &:hover {
              color: var(--theme-color);
              transition: all 0.5s ease;
            }
          }
        }
      }
    }

    &.full-width-right-box {
      .product-contain {
        p {
          width: 70%;

          @include mq-max(md) {
            width: 100%;
          }
        }
      }

      .secure-site-sec {
        img {
          width: 41%;
        }
      }

      .progress {
        width: 75%;

        @include mq-max(2xl) {
          width: 100%;
        }
      }
    }
  }

  .progress-sec {
    border-bottom: 1px solid $border-color;
    padding-bottom: 16px;

    .left-progressbar {
      margin-top: 18px;

      h6 {
        margin-bottom: 10px;
      }

      .progress {
        height: 10px;

        &.danger-progress {
          .progress-bar {
            background-color: #ff7272;
          }
        }

        &.warning-progress {
          .progress-bar {
            background-color: #ffa53b;
          }
        }

        &.success-progress {
          .progress-bar {
            background-color: var(--theme-color);
          }
        }
      }

      .progress-bar {
        background-color: var(--theme-color);
      }
    }
  }

  .product-main {
    .slider-image {
      img {
        width: 100%;
      }
    }
  }

  .right-sidebar-box {
    position: sticky;
    top: 10px;

    .product-right-sidebar {
      li {
        + li {
          margin-top: 0;

          &::after {
            top: -14px;
            width: calc(100% - 103px);
          }
        }
      }
    }

    .product-list {
      li {
        .offer-product {
          gap: calc(12px + (23 - 12) * ((100vw - 992px) / (1920 - 992)));
        }
      }
    }
  }
}

.dynamic-checkout {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  max-width: 600px;
  > * {
    width: 100%;
  }

  button {
    width: 100%;
    color: #fff;
    text-transform: uppercase;

    &.border-theme-color {
      border: 1px solid var(--theme-color);
      background-color: transparent;
      color: var(--theme-color);
      transition: none;
      .spinning {
        &::before {
          border-right: 3px solid #1aa488;
        }
      }
    }

    &:hover {
      background-color: var(--theme-color);
      border-color: var(--theme-color);
      color: #fff;
    }

    i {
      animation: ring 4s 0.7s ease-in-out infinite;
      line-height: 1;
      margin-top: -3px;
      font-size: 19px;
    }
  }
}

.related-product-2 {
  .bundle-sec {
    .related-box {
      .related-image {
        width: auto;

        > ul {
          > li {
            width: 205px;
            min-width: 205px;
          }
        }
      }

      .budle-list {
        > ul {
          flex-direction: column;
          align-items: flex-start;

          > li {
            width: unset;
          }
        }
      }
    }
  }
}

.related-product-2 {
  .related-product {
    .product-title-2 {
      margin-top: 0;

      h4 {
        font-size: calc(21px + (25 - 21) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        margin-bottom: calc(
          16px + (21 - 16) * ((100vw - 320px) / (1920 - 320))
        );
      }
    }
  }

  .related-box {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 15px;

    @media (max-width: 1577px) {
      flex-wrap: wrap;
    }

    .related-image {
      touch-action: pan-x;
      overflow: auto;
      padding-bottom: 13px;

      > ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));

        > li {
          width: 225px;
          min-width: 225px;
          position: relative;

          + li {
            &::before {
              @include pos($pos: absolute, $content: "\2b");
              @include center(vertical);
              left: calc(
                -18px + (-26 - -18) * ((100vw - 320px) / (1920 - 320))
              );
              font-size: calc(
                14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))
              );
              @include font;
              color: $content-color;

              body.rtl & {
                left: unset;
                right: calc(
                  -18px + (-26 - -18) * ((100vw - 320px) / (1920 - 320))
                );
              }
            }
          }

          a {
            display: block;
            overflow: hidden;

            .image-box {
              background-color: $light-gray;
              width: calc(
                136px + (170 - 136) * ((100vw - 320px) / (1920 - 320))
              );
              height: calc(
                136px + (170 - 136) * ((100vw - 320px) / (1920 - 320))
              );
              padding: 10px;
              @include flex_common;
              position: relative;
              border-radius: calc(
                6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))
              );

              .form-check {
                position: absolute;
                top: 8px;
                left: 8px;
                padding: 0;

                .checkbox_animated {
                  &::after {
                    border: 1px solid #4a5567;
                    border-radius: 3px;
                  }
                }
              }
            }

            .related-content {
              margin-top: 12px;
              padding: 0 calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));

              h5 {
                font-size: 15px;
                line-height: 1.2;
                color: $title-color;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
              }

              h6 {
                margin-top: 5px;
                color: var(--theme-color);

                del {
                  color: $content-color;
                  margin-left: 4px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }

    .budle-list {
      gap: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;

        > li {
          width: 100%;
          display: block;

          .form-check {
            display: flex;
            padding: 0;
            margin-bottom: 0;
            align-items: center;

            .checkbox_animated {
              margin-top: -4px;
            }

            .form-check-label {
              span {
                font-size: calc(
                  15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))
                );
                color: $content-color;

                span {
                  color: $title-color;
                  font-weight: 600;
                }
              }
            }
          }

          &.contant {
            margin-top: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)));

            h5 {
              font-size: 17px;
              font-weight: 600;
              color: $content-color;
            }

            h4 {
              margin-top: 8px;
              font-weight: 700;

              del {
                font-size: calc(
                  14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))
                );
                margin-left: 5px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

.sticky-bottom-cart {
  position: fixed;
  bottom: -160px;
  width: 100%;
  z-index: 2;
  background-color: $white;
  padding: 14px 0;
  box-shadow: 0 0 3px 1px rgba($title-color, 0.06);
  transition: all 0.3s ease;

  @include mq-max(md) {
    transition: none;
  }

  .qty-box .input-group {
    background-color: $light-gray;
    border-radius: 7px;
    padding: 4px;
    text-align: center;
    z-index: 0;
  }

  .cart-content {
    @include flex_common($dis: flex, $align: center, $justify: space-between);

    .selection-section {
      .form-group {
        > * {
          display: flex;
          align-items: center;
          gap: calc(6px + (10 - 6) * ((100vw - 575px) / (1920 - 575)));
        }
      }
    }
  }

  .product-image {
    display: flex;
    align-items: center;

    img {
      width: 60px;
      height: 70px;
      object-fit: cover;
      object-position: top;
    }

    .content {
      margin-left: 12px;
      color: $title-color;
      margin-top: 0;

      body.rtl & {
        margin-left: 0;
        margin-right: 12px;
      }

      @include mq-max(xs) {
        display: block;
      }

      @include mq-max(sm) {
        display: none;
      }

      h5,
      h6 {
        margin-bottom: 0;
      }

      h5 {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
      }

      h6 {
        font-size: 15px;
        margin-top: 6px;

        del {
          margin-left: 7px;
          color: $content-color;

          body.rtl & {
            margin-left: unset;
            margin-right: 7px;
          }
        }

        span {
          color: var(--theme-color);
          margin-left: 5px;

          body.rtl & {
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .selection-section {
    display: flex;
    align-items: center;
    margin: 0 calc(9px + (25 - 9) * ((100vw - 575px) / (1920 - 575))) 0 auto;
    gap: calc(6px + (10 - 6) * ((100vw - 575px) / (1920 - 575)));

    body.rtl & {
      margin: 0 auto 0 calc(9px + (25 - 9) * ((100vw - 575px) / (1920 - 575)));
    }

    .form-control {
      background-color: #f8f8f8;
      border: 1px solid #f8f8f8;
      text-transform: capitalize;
      max-width: 100%;

      &:focus {
        box-shadow: none;
      }
    }

    .product-qty {
      height: calc(42px + (51 - 42) * ((100vw - 576px) / (1920 - 576)));
      width: 100%;
      max-width: calc(130px + (170 - 130) * ((100vw - 575px) / (1920 - 575)));

      .input-group {
        button {
          height: 100%;

          &:hover {
            background-color: $white;

            i {
              color: var(--theme-color);
            }
          }
        }
      }
    }

    .form-group {
      width: 220px;

      @include mq-max(xl) {
        width: 190px;
      }

      @include mq-max(md) {
        margin-right: 0;
        width: unset;
      }
    }
  }

  .add-btn {
    @include mq-max(sm) {
      @include flex_common;
    }

    a {
      padding: 12px 40px;

      @include mq-max(sm) {
        width: 100%;
        @include flex_common;
      }

      &.wishlist-btn {
        display: none;
        background-color: $light-gray;
        color: $title-color;

        &:hover {
          background-color: var(--theme-color);
          border-color: var(--theme-color);
          color: $white;
        }

        @include mq-max(sm) {
          display: flex;
        }
      }

      + a {
        @include mq-max(sm) {
          margin-left: 8px;

          body.rtl & {
            margin-right: 8px;
            margin-left: unset;
          }
        }
      }

      i {
        margin-right: 7px;
        animation: ring 4s 0.7s ease-in-out infinite;

        body.rtl & {
          margin-right: unset;
          margin-left: 7px;
        }
      }
    }
  }
}

.delivery-info {
  padding-bottom: 20px;
  border-bottom: 1px solid $border-color;

  li {
    display: flex;
    align-items: center;
    gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #777;

    @include mq-max(md) {
      justify-content: center;
    }

    i {
      font-size: 20px;
      line-height: 1;
    }

    + li {
      margin-top: 4px;
    }
  }
}

.recently-purchase {
  background: $white;
  border: 0;
  border-radius: 6px;
  bottom: -130px;
  left: 20px;
  padding: 0;
  position: fixed;
  text-align: left;
  width: auto;
  z-index: 8;
  box-shadow: 0 0 4px 0 #d0d0d0;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: all 0.6s ease;

  body.rtl & {
    text-align: right;
  }

  @media (max-width: 380px) {
    width: auto;
    margin-inline: 10px;
    left: 0;
  }

  &.show {
    bottom: 20px;
    transition: all 0.6s ease;
  }

  .media-height {
    width: 64px;
    height: 85px;
    object-fit: contain;
  }

  .close-popup {
    position: absolute;
    right: 7px;
    top: 6px;
    opacity: 0.5;
    color: $title-color;

    body.rtl & {
      right: unset;
      left: 7px;
    }
  }

  .media-body {
    padding: 0 30px 0 12px;
    @include rtl(padding, 0 30px 0 12px, 0 12px 0 30px);

    .title {
      font-weight: 600;
    }

    .product-name {
      color: var(--theme-color);
    }

    .timeAgo {
      display: block;
    }
  }
}

.stickyCart {
  .sticky-bottom-cart {
    bottom: 0;
    transition: all 0.3s ease;

    @include mq-max(md) {
      bottom: calc(69px + (72 - 69) * ((100vw - 320px) / (767 - 320)));
      transition: none;
    }
  }

  .recently-purchase {
    &.show {
      bottom: 113px;
    }
  }

  .tap-top {
    bottom: 110px;
  }
}

.secure-site-sec {
  margin: 20px 0 0;

  h4 {
    font-weight: 600;
    margin-bottom: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.4px;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
  }

  img {
    width: calc(80% + (65 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
  }
}

.size-delivery-info {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
  flex-wrap: wrap;
  margin-top: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid $border-color;

  @include mq-max(md) {
    justify-content: center;
  }

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $title-color;

    &:hover {
      color: var(--theme-color);
    }
  }
}

.product-count {
  ul {
    display: inline-flex;
    align-items: center;
    gap: 3px 15px;
    margin-bottom: 16px;
    flex-wrap: wrap;

    @include mq-max(sm) {
      display: inline-grid;
      gap: 0;
    }

    li {
      display: flex;
      align-items: center;
      gap: 10px;

      h6 {
        font-size: 15px;
      }

      i {
        color: #1aa488;
        font-size: 20px;
      }
    }
  }
}
